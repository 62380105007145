import React, { useState } from "react";
import Sidebar from './Training/Sidebar';
import OrganizationProgressTable from './Stats/OrgStats';
import { useTranslation } from "react-i18next";

export default function Stats() {
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const tabs = {
    "for_all": 0,
    "for_students": 1,
    "for_teachers": 2,
  };

  const handleMenuClick = (menuId) => {
    console.log("Menu clicked:", menuId);
    setSelectedMenu(menuId);
    // You can navigate or update state based on menu selection here
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar onMenuClick={handleMenuClick} />
        <div className="flex-1 space-y-6">
          <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-6">
            <div className="flex bg-gray-100 p-2 rounded-lg mb-2">
              {Object.entries(tabs).map(([key, value]) => (
                <button
                  key={value}
                  onClick={() => setActiveTab(value)}
                  className={`flex-grow text-center px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                    activeTab === value
                      ? "bg-white text-black shadow"
                      : "bg-transparent text-gray-500"
                  }`}
                >
                  {t(`training.stats.tabs.${key}`)}
                </button>
              ))}
            </div>
            <OrganizationProgressTable forWhom={activeTab}/>
          </div>
        </div>
      </div>
    </div>
  );
}
